// import Image from "next/image"
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useState, useEffect } from 'react'
import { useToast } from "@/components/ui/use-toast"
import VideoThumbnail from 'react-video-thumbnail';
import ReactPlayer from 'react-player';
import { Toaster } from "@/components/ui/toaster"
import { Progress } from "@/components/ui/progress"
import { Skeleton } from "@/components/ui/skeleton"
import { Textarea } from "@/components/ui/textarea"

import { PulseLoader } from "react-spinners";
import './dashboard.css'
import { TriangleAlert } from 'lucide-react';
import { Youtube } from 'lucide-react';

import useDownloader from 'react-use-downloader';
import { SquareArrowUpRight } from 'lucide-react';

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"

import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
    File,
    Home,
    LineChart,
    ListFilter,
    MoreHorizontal,
    Package,
    Package2,
    PanelLeft,
    PlusCircle,
    Search,
    Settings,
    ShoppingCart,
    Users2,
} from "lucide-react"

import { Badge } from "@/components/ui/badge"
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import DashboardLayout from "@/custom_component/dashboardLayout";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"

import { auth } from "./../firebase-config"
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";
import moment from "moment";

const categories = [
    {
        "kind": "youtube#videoCategory",
        "etag": "grPOPYEUUZN3ltuDUGEWlrTR90U",
        "id": "1",
        "snippet": {
            "title": "Film & Animation",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "Q0xgUf8BFM8rW3W0R9wNq809xyA",
        "id": "2",
        "snippet": {
            "title": "Autos & Vehicles",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "qnpwjh5QlWM5hrnZCvHisquztC4",
        "id": "10",
        "snippet": {
            "title": "Music",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "HyFIixS5BZaoBdkQdLzPdoXWipg",
        "id": "15",
        "snippet": {
            "title": "Pets & Animals",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "PNU8SwXhjsF90fmkilVohofOi4I",
        "id": "17",
        "snippet": {
            "title": "Sports",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "ANnLQyzEA_9m3bMyJXMhKTCOiyg",
        "id": "19",
        "snippet": {
            "title": "Travel & Events",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "0Hh6gbZ9zWjnV3sfdZjKB5LQr6E",
        "id": "20",
        "snippet": {
            "title": "Gaming",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "cHDaaqPDZsJT1FPr1-MwtyIhR28",
        "id": "22",
        "snippet": {
            "title": "People & Blogs",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "3Uz364xBbKY50a2s0XQlv-gXJds",
        "id": "23",
        "snippet": {
            "title": "Comedy",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "0srcLUqQzO7-NGLF7QnhdVzJQmY",
        "id": "24",
        "snippet": {
            "title": "Entertainment",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "bQlQMjmYX7DyFkX4w3kT0osJyIc",
        "id": "25",
        "snippet": {
            "title": "News & Politics",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "Y06N41HP_WlZmeREZvkGF0HW5pg",
        "id": "26",
        "snippet": {
            "title": "Howto & Style",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "yBaNkLx4sX9NcDmFgAmxQcV4Y30",
        "id": "27",
        "snippet": {
            "title": "Education",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "Mxy3A-SkmnR7MhJDZRS4DuAIbQA",
        "id": "28",
        "snippet": {
            "title": "Science & Technology",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "p3lEirEJApyEkuWpaGEHoF-m-aA",
        "id": "29",
        "snippet": {
            "title": "Nonprofits & Activism",
            "assignable": true,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    },
    {
        "kind": "youtube#videoCategory",
        "etag": "Y0u9UAQCCGp60G11Arac5Mp46z4",
        "id": "34",
        "snippet": {
            "title": "Comedy",
            "assignable": false,
            "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
    }
]

function Videos({ posts, loading , idToken}) {

    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [tags, setTags] = useState('')
    const [categoryId, setCategoryId] = useState(22)
    const [videolink, setVideoLink] = useState('')
    const [ytemails, setytemails] = useState([])
    const [ytemailid, setytemailid] = useState('')
    const [showVideoSpinner, setShowVideoSpinner] = useState(false);



    if (loading) {
        return
        (<div className="grid gap-2 pt-8">
            <div className="flex flex-col space-y-2">
                <Skeleton className="h-[125px] w-auto rounded-xl" />
                <div className="space-y-2">
                    <Skeleton className="h-4 w-[250px]" />
                    <Skeleton className="h-4 w-[200px]" />
                </div>
            </div>
        </div>)
    }
    const { toast } = useToast()
    let navigate = useNavigate();


    const handleTitleChange = (event) => {
        const value = event.target.value;
        setTitle(value);
    };

    const handleTagsChange = (event) => {
        const value = event.target.value;
        setTags(value);
    };

    const handleDescChange = (event) => {
        const value = event.target.value;
        setDesc(value);
    };

    const handleVideoChange = (event) => {
        const value = event.target.value;
        setVideoLink(value);
    };

    const onDropDownOpen = (event, video) => {
        event.preventDefault()
        setTitle(video.content_title);
        setDesc(video.description);
        setTags(video.hashtags)
        setCategoryId(video.category_id)
        setVideoLink((video.video_link+"").replace("shortsgenie.s3.amazonaws.com","dlzumlszc4wp4.cloudfront.net"));
        event.preventDefault()
    }
    const postVideo = async () => {
        try {
          const response = await fetch(`${import.meta.env.VITE_ENV}/content/post_video_yt_now`, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'app-token': idToken,
            },
            body: JSON.stringify({
                'video_link': videolink,
                'title': title,
                'hashtags': tags,
                'description': desc,
                'category_id': categoryId,
                'yt_email': ytemailid
              }),
          });

          if (response.ok) {
            const { message: message, success: success, error_code: error_code, data:data } = await response.json();
            if(!success && error_code == 402){
                toast({ title: message, description: "Mutliple Youtube accounts found. Choose one" });
                setytemails(data)
            }
            else if(!success && error_code == 500){
                toast({ title: "Connect Youtube account from Profile section", description: "Go to Profile and click Connect Youtube to Integrate." });

            } else if(!success && error_code == 400){
                toast({ title: "Something went wrong", description: "Please try again later" });

            }
            else{
                toast({ title: "Successfully posted in Youtube", description: "Please check your youtube channel" });

            }

          } else if (response.status === 404 || response.status === 401) {
            navigate("/");
            toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
          } else {
            const errorData = await response.json();
            console.error('Error:', errorData.message || response.statusText);
            toast({ title: "Something went wrong", description: "Please try again" });
          }
        } catch (error) {
          if (error.name !== 'AbortError') {
            console.error("Fetch error:", error);
          }
        }
    };

    const handleDownload = (url, fileName) => {
        toast({ title: "Download started", description: "This might take couple of minutes to complete" });

        fetch(url, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache'
        }).then((response) => response.blob())
            .then((blob) => {

                fileName = fileName + '.mp4'
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement("a");
                link.href = url;
                link.download = fileName || "downloaded-file";
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                toast({ title: "Error fetching the file", description: "Please try again later" });

                // console.error("Error fetching the file:", error);
            });
    };


    return (
        <CardContent className=" p-2">
            <Table >
                <TableHeader>
                    <TableRow className=" bg-slate-100">
                        <TableHead className="w-[80px]"><span className="sr-only"></span></TableHead>
                        <TableHead className="  md:w-[400px]  sm:table-cell">Name</TableHead>
                        <TableHead className="hidden md:table-cell">Time Created</TableHead>
                        <TableHead >Type</TableHead>
                        {/* <TableHead className="hidden md:table-cell">Hashtags</TableHead> */}
                        {posts.length > 0 ? <TableHead className="hidden md:table-cell">Status</TableHead> : ''}
                        <TableHead><span className="sr-only">Actions</span></TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody >
                    {posts.length > 0 ? posts.map(video => (
                        <TableRow key={video.id} className=" border" >
                            <TableCell className="p-0  ">
                                {video.video_status === 'Done' && video.is_failed === false ? (
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <div className="videoContainer">
                                                <img className="cursor-pointer p-0" width="100%" height="auto" src={(video.thumbnail_link+"").replace("shortsgenie.s3.amazonaws.com","dlzumlszc4wp4.cloudfront.net")} alt="Thumbnail" />
                                            </div>
                                        </DialogTrigger>
                                        <DialogContent className="sm:max-w-[425px] p-2">
                                            <div className='player-wrapper'>
                                                <ReactPlayer className='react-player fixed-bottom' url={(video.video_link+"").replace("shortsgenie.s3.amazonaws.com","dlzumlszc4wp4.cloudfront.net")} width='100%' height='100%' controls playing
                                                    onPlay={() => setShowVideoSpinner(false)}
                                                    onBuffer={() => setShowVideoSpinner(true)}
                                                    onReady={() => setShowVideoSpinner(false)}
                                                    onError={() => setShowVideoSpinner(true)}
                                                    config={{
                                                        file: { 
                                                          attributes: { 
                                                            poster: './imgstyles/spinner.gif' 
                                                          } 
                                                        } 
                                                      }}
                                                />
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                ) : (
                                    <div className="videoContainer">
                                        <img width="100%" height="auto" src="/noimage.png" alt="Processing" />
                                    </div>
                                )}
                            </TableCell>
                            <TableCell className="font-medium">
                                {video.video_status === 'Done' && video.is_failed === false ? (
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <div className="cursor-pointer">{video.content_title}</div>
                                        </DialogTrigger>
                                        <DialogContent className="sm:max-w-[425px] p-2">
                                            <div className='player-wrapper'>
                                                <ReactPlayer className='react-player fixed-bottom' url={(video.video_link+"").replace("shortsgenie.s3.amazonaws.com","dlzumlszc4wp4.cloudfront.net")} width='100%' height='100%' controls playing
                                                    onPlay={() => setShowVideoSpinner(false)}
                                                    onBuffer={() => setShowVideoSpinner(true)}
                                                    onReady={() => setShowVideoSpinner(false)}
                                                    onError={() => setShowVideoSpinner(true)}
                                                    config={{
                                                        file: { 
                                                          attributes: { 
                                                            poster: './imgstyles/spinner.gif' 
                                                          } 
                                                        } 
                                                      }}
                                                />
                                            </div>
                                        </DialogContent>
                                    </Dialog>
                                ) : (
                                    <div>{video.content_title}</div>
                                )}
                            </TableCell>
                            <TableCell className="hidden md:table-cell">
                                {video.video_status === 'Done' ? (
                                    <div>{moment(video.end_time).fromNow()}</div>
                                ) : (
                                    <div>{moment(video.start_time).fromNow()}</div>
                                )}
                            </TableCell>
                            <TableCell>{video.video_type}</TableCell>
                            {/* <TableCell className="hidden md:table-cell">{video.hashtags}</TableCell> */}
                            <TableCell className="hidden md:table-cell">{video.is_failed === true ? <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger> <TriangleAlert /></TooltipTrigger>
                                    <TooltipContent>
                                        <p>Some error occured. We are working on it.</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                                :
                                video.video_status === 'Done' ? 'Completed' : <div className=" w-fit">Processing<PulseLoader color="#56726d" size={8} /></div>} </TableCell>
                            <TableCell>
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button aria-haspopup="true" size="icon" variant="ghost" disabled={video.video_status !== 'Done'}>
                                            <MoreHorizontal className="h-4 w-4" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent align="end">
                                        {video.video_type != 'Scheduled' ? <Dialog>
                                            <DialogTrigger asChild>
                                                <DropdownMenuItem onSelect={(e) => {
                                                    onDropDownOpen(e, video);
                                                }}>Post in Youtube  </DropdownMenuItem>
                                            </DialogTrigger>
                                            <DialogContent className="sm:max-w-[425px] p-8"> 
                                                <DialogHeader>
                                                    <DialogTitle>Post in Youtube</DialogTitle>
                                                    <DialogDescription>
                                                    </DialogDescription>
                                                </DialogHeader>
                                                <div className=" items-center">
                                                    <Input
                                                        id="title"
                                                        className=" h-8 hidden "
                                                        value={(video.video_link+"").replace("shortsgenie.s3.amazonaws.com","dlzumlszc4wp4.cloudfront.net")}
                                                        onChange={handleVideoChange}
                                                    />
                                                    <Label htmlFor="title" >
                                                        Title
                                                    </Label>
                                                    <Input
                                                        id="title"
                                                        className=" h-8 "
                                                        defaultValue={video.content_title}
                                                        onChange={handleTitleChange}
                                                    />
                                                </div>

                                                <div className=" items-center">
                                                    <Label htmlFor="hashtags">
                                                        Tags
                                                    </Label>
                                                    <Input
                                                        id="hashtags"
                                                        className=" h-8"
                                                        defaultValue={video.hashtags ? video.hashtags : ''}
                                                        onChange={handleTagsChange}
                                                    />
                                                </div>

                                                <div className=" items-center">
                                                    <Label htmlFor="description">
                                                        Description
                                                    </Label>
                                                    <Textarea
                                                        id="description"
                                                        className=" h-4"
                                                        defaultValue={video.description ? video.description : ''}
                                                        onChange={handleDescChange}
                                                    />
                                                </div>

                                                <div className=" items-center">
                                                    <Label htmlFor="category">
                                                        Category
                                                    </Label>
                                                    <Select id="category" className=" w-auto" defaultValue={video.category_id ? video.category_id : "24"} onValueChange={(e) => { setCategoryId(e); }}>
                                                        <SelectTrigger className="w-[180px]">
                                                            <SelectValue placeholder="Select a category" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                {categories.map(cateory =>
                                                                    <SelectItem value={cateory.id} active>{cateory.snippet.title}</SelectItem>
                                                                )}
                                                           
                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </div>
                                                {ytemails.length > 0 ? <div className=" items-center">
                                                    <Label htmlFor="yt_email">
                                                        Select Youtube Account
                                                    </Label>
                                                    <Select id="yt_email" className=" w-auto" defaultValue={ytemails[0]} onValueChange={(e) => { setytemailid(e); }}>
                                                        <SelectTrigger className="w-auto">
                                                            <SelectValue placeholder="Select a Youtube acount" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                {ytemails.map(ytemail =>
                                                                    <SelectItem value={ytemail} active>{ytemail}</SelectItem>
                                                                )}
                                                           
                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </div> : '' }
                                                <DialogFooter>
                                                    <Button type="submit" onClick={postVideo}>Submit</Button>
                                                </DialogFooter>
                                            </DialogContent>
                                        </Dialog> :
                                         <DropdownMenuItem onClick={() => window.open("https://www.youtube.com/shorts/".concat(video.youtube_id), "_blank")} ><SquareArrowUpRight/>&nbsp; View in Youtube</DropdownMenuItem>}
                                        <DropdownMenuItem onClick={() => handleDownload((video.video_link+"").replace("shortsgenie.s3.amazonaws.com","dlzumlszc4wp4.cloudfront.net"), video.content_title)} disabled={video.is_failed === true}>Download</DropdownMenuItem>
                                        <DropdownMenuItem onClick={() => toast({ title: "Action not implemented yet." })}>Delete</DropdownMenuItem>

                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </TableCell>
                        </TableRow>
                    )) : (
                        <TableRow>
                            <TableCell colSpan="7" className="text-center">No videos found</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </CardContent>

    );
};
export default Videos;