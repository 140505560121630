import * as React from "react"
import { useState, useEffect } from 'react'

import { Mail } from "lucide-react"

import { Button } from "@/components/ui/button"
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast"


import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"

import { signInWithGooglePopup } from "./../firebase-config"
import { auth } from "./../firebase-config"

import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";


const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        navigate("/dashboard");
      }
    });
  }, [])

 
  const { toast } = useToast()
  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    console.log(response);
   
    const loginResponse = response;
    try{
      let idToken = loginResponse._tokenResponse.idToken;
      let mail = loginResponse.user.email;
      let displayName = loginResponse.user.displayName;
      let photoURL = loginResponse.user.photoURL;
     
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/add_user`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken
        },
        body: JSON.stringify({
          'email': mail,
          'displayName': displayName, 
          'photoUrl': photoURL
        })
      });

      if (response.status === 200) {
        navigate("/dashboard");
        
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({
          title: "Login Failed",
          description: "Facing issue with Login. Please try again",
        })
      }
      
      
    }catch{
      console.log("Gmail Login Failed");
     
      
    }

}

    return (
        <div className="flex justify-center items-center h-screen card">
    <Card className="w-[350px]">
      <CardHeader>
        <CardTitle>Login to ShortsGenie</CardTitle>
      </CardHeader>
      <CardContent>
        <form>
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="name">Email</Label>
              <Input id="email" type="email" />
            </div>
            <div className="flex flex-col space-y-1.5 ">
              <Label htmlFor="name">Password</Label>
              <Input id="password" type="password"/>
            </div>
  
          </div>
        </form>
      </CardContent>
      <CardFooter className="flex justify-center items-center card">
      <div className="flex justify-center items-center card">
      <Button>
      <Mail className="mr-2 h-4 w-4" /> Login with Email
    </Button>
    </div>
      </CardFooter>
      <Separator />
      <div className="flex justify-center items-center card pt-3 pb-3 ">

      <Button onClick={logGoogleUser} className=" bg-sky-600">
      <Mail className="mr-2 h-4 w-4 items-center" /> Login with Google
    </Button>
    </div>

    </Card>
    </div>
  )
}
export default Login;

