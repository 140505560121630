// import Image from "next/image"
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useState, useEffect } from 'react'
import { useToast } from "@/components/ui/use-toast"
import VideoThumbnail from 'react-video-thumbnail';
import ReactPlayer from 'react-player';
import { Toaster } from "@/components/ui/toaster"
import { Progress } from "@/components/ui/progress"
import { Skeleton } from "@/components/ui/skeleton"
import { Textarea } from "@/components/ui/textarea"

import { PulseLoader } from "react-spinners";
import './dashboard.css'
import { TriangleAlert } from 'lucide-react';
import { Youtube } from 'lucide-react';

import useDownloader from 'react-use-downloader';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer"


import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  File,
  Home,
  LineChart,
  ListFilter,
  MoreHorizontal,
  Package,
  Package2,
  PanelLeft,
  PlusCircle,
  Search,
  Settings,
  ShoppingCart,
  Users2,
} from "lucide-react"

import { Badge } from "@/components/ui/badge"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import DashboardLayout from "@/custom_component/dashboardLayout";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"

import { auth } from "../firebase-config"
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";
import moment from "moment";
import SchedulerEdit from "./SchedulerEdit";
import ViewSchedulerConfig from "./ViewSchedulerConfig";


function SchedulerList({ schedulers, setScheduler, loading, idToken }) {

  const [title, setTitle] = useState('')
  const [desc, setDesc] = useState('')
  const [tags, setTags] = useState('')
  const [categoryId, setCategoryId] = useState(22)
  const [videolink, setVideoLink] = useState('')
  const [ytemails, setytemails] = useState([])
  const [ytemailid, setytemailid] = useState('')
  const [detailopen, setDetailOpen] = useState(false)

  if (loading) {
    return
    (<div className="grid gap-2 pt-8">
      <div className="flex flex-col space-y-2">
        <Skeleton className="h-[125px] w-auto rounded-xl" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-[250px]" />
          <Skeleton className="h-4 w-[200px]" />
        </div>
      </div>
    </div>)
  }
  const { toast } = useToast()
  let navigate = useNavigate();


  const handleTitleChange = (event) => {
    const value = event.target.value;
    setTitle(value);
  };

  const handleTagsChange = (event) => {
    const value = event.target.value;
    setTags(value);
  };

  const handleDescChange = (event) => {
    const value = event.target.value;
    setDesc(value);
  };

  const handleVideoChange = (event) => {
    const value = event.target.value;
    setVideoLink(value);
  };

  function updateMapById(id, newValues) {
    let map = schedulers;
    const index = map.findIndex(item => item.id === id);

    if (index !== -1) {
      // Update the object at the found index
      map[index] = { ...map[index], ...newValues };
    } else {
      console.log('ID not found');
    }
    return map;
  }

  function removeMapById(id) {
    let map = schedulers;
    return map.filter(item => item.id !== id);
  }

  const pauseSchedule = async (schedulerID) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/pause_schedule/${schedulerID}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken,
        },

      });

      if (response.ok) {
        const { data: data, success: success, error_code: error_code, quota_exceeded: quota_exceeded } = await response.json();

        if (success == true) {
          toast({ title: "Schedule paused", description: "Successfully paused the schedule." });
          let mapp = updateMapById(schedulerID, data)
          setScheduler([...mapp])
        }

      } else if (response.status === 404 || response.status === 401) {
        navigate("/");
        toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({ title: "Something went wrong", description: "Please try again" });
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Fetch error:", error);
      }
    }
  };


  const unpauseSchedule = async (schedulerID) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/unpause_schedule/${schedulerID}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken,
        },

      });

      if (response.ok) {
        const { data: data, success: success, error_code: error_code, quota_exceeded: quota_exceeded } = await response.json();

        if (success == true) {
          toast({ title: "Schedule restarted", description: "Successfully activated the schedule." });
          let mapp = updateMapById(schedulerID, data)
          setScheduler([...mapp])
        }

      } else if (response.status === 404 || response.status === 401) {
        navigate("/");
        toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({ title: "Something went wrong", description: "Please try again" });
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Fetch error:", error);
      }
    }
  };


  const deleteSchedule = async (schedulerID) => {
    toast({ title: "Initiating deletion for the schedule" });
    try {
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/delete_schedule/${schedulerID}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken,
        },

      });

      if (response.ok) {
        const { data: data, success: success, error_code: error_code, quota_exceeded: quota_exceeded } = await response.json();

        if (success == true) {
          toast({ title: "Schedule deleted", description: "Successfully deleted the schedule." });
          let mapp = removeMapById(schedulerID)
          setScheduler([...mapp])
        }

      } else if (response.status === 404 || response.status === 401) {
        navigate("/");
        toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({ title: "Something went wrong", description: "Please try again" });
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Fetch error:", error);
      }
    }
  };




  return (
    <CardContent>
      <Table >
        <TableHeader>
          <TableRow className=" bg-slate-100">
            <TableHead className="  md:table-cell">Name</TableHead>
            <TableHead className="hidden md:table-cell">Schedule</TableHead>
            <TableHead className="hidden md:table-cell">Time Updated</TableHead>
            <TableHead className="hidden md:table-cell">Theme</TableHead>
            <TableHead className=" md:table-cell">Status</TableHead>
            <TableHead><span className="sr-only">Actions</span></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {schedulers.length > 0 ? schedulers.map(cron => (
            <TableRow key={cron.id}>

              <TableCell className="font-medium">
                <div>{cron.name}</div>
              </TableCell>
              <TableCell className="hidden md:table-cell">
                <div >{cron.value.text}</div>
              </TableCell>
              <TableCell className="hidden md:table-cell">
                <div>{moment(cron.updated_time).fromNow()}</div>
              </TableCell>
              <TableCell className="hidden md:table-cell">{cron.theme_name}</TableCell>
              {/* <TableCell className="hidden md:table-cell">{video.hashtags}</TableCell> */}
              <TableCell>
                {cron.status == 'Active' ? <Badge >{cron.status}</Badge> :
                  cron.status == 'Paused - Buy credits to resume' ? <Badge className=" rounded-none text-red-700 border-0" variant="outline">{cron.status}</Badge> : <Badge variant="secondary">{cron.status}</Badge>
                }</TableCell>


              <TableCell>
                <DropdownMenu>

                  <DropdownMenuTrigger asChild>
                    <Button aria-haspopup="true" size="icon" variant="ghost">
                      <MoreHorizontal className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                   
                  <DropdownMenuContent align="end">
                    <Dialog >
                      <DialogTrigger asChild>
                      </DialogTrigger>
                      <ViewSchedulerConfig
                        schedulerName_={cron.name}
                        schedulerId_={cron.id}
                        ytemailid_={cron.yt_email}
                        audiotype_={cron.audio_type}
                        selectedTimezone_={cron.timezone}
                        theme_={cron.theme_obj}
                        value_={cron.value}
                        idToken_={idToken}
                        imgstyle_={cron.image_style}
                        schedulers={schedulers}
                        setSchedulers={setScheduler}
                        cronstatus_={cron.status}
                      />
                    </Dialog>

                    <Dialog >
                      <DialogTrigger asChild>
                      </DialogTrigger>
                      <SchedulerEdit
                        schedulerName_={cron.name}
                        schedulerId_={cron.id}
                        ytemailid_={cron.yt_email}
                        audiotype_={cron.audio_type}
                        selectedTimezone_={cron.timezone}
                        theme_={cron.theme_obj}
                        value_={cron.value}
                        idToken_={idToken}
                        imgstyle_={cron.image_style}
                        schedulers={schedulers}
                        setSchedulers={setScheduler}
                       
                      />
                    </Dialog>
                    {cron.status == 'Paused' ? <DropdownMenuItem onClick={() => unpauseSchedule(cron.id)}>Restart</DropdownMenuItem> :
                      cron.status == 'Active' ? <DropdownMenuItem onClick={() => pauseSchedule(cron.id)}>Pause</DropdownMenuItem> : ''}
                    <DropdownMenuItem onClick={() => deleteSchedule(cron.id)}>Delete</DropdownMenuItem>

                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan="7" className="text-center">No schedulers found</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </CardContent>

  );
};
export default SchedulerList;