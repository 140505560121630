// import Image from "next/image"
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useState, useEffect, use } from 'react'
import { useToast } from "@/components/ui/use-toast"
import { Separator } from "@/components/ui/separator"
import { AudioPlayer } from 'react-audio-play';
import Cron from 'react-cron-generator'
import './cron-builder.css'

// import { RocketIcon } from "@radix-ui/react-icons"

import { HEADER } from 'react-cron-generator';



import { Badge } from "@/components/ui/badge"


import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Label } from "@/components/ui/label"
import parser from 'cron-parser';


import moment from 'moment-timezone';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"


import { auth } from "../firebase-config"
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";

const ViewSchedulerConfig = ({ schedulerName_, 
  schedulerId_, 
  ytemailid_, 
  audiotype_, 
  selectedTimezone_, 
  theme_, 
  value_, 
  idToken_,
  imgstyle_,
  schedulers,
  setSchedulers,
  cronstatus_
}) => {
  const [themes, setThemes] = useState([]);
  const [imgstyle, setImgstyle] = useState(imgstyle_);
  const [audiotype, setAudiotype] = useState(audiotype_);
  const [theme, setTheme] = useState({
    "id": theme_.id,
    "name": theme_.name,
    "enabled": true,
    "desc": ""
  });
  const [ytemailid, setytemailid] = useState(ytemailid_)
  const [cronstatus, setCronstatus] = useState(cronstatus_)
  const [schedulerName, setschedulerName] = useState(schedulerName_);
  const { toast } = useToast()
  let navigate = useNavigate();
  const [ytemails, setytemails] = useState([ytemailid_])
  const [utctime, setUtcTime] = useState('')
  const [loading, setLoading] = useState(false);

  // const [selectedTimezone, setSelectedTimezone] = useState(
  //   Intl.DateTimeFormat().resolvedOptions().timeZone,
  // )

  const [selectedTimezone, setSelectedTimezone] = useState(
    selectedTimezone_
  )

  let imgStyles = [
    {
      "style": "Random",
      "backend_name": "random",
      "sample_img": "/imgstyles/shuffle.png"
    },
    {
      "style": "Pop Art",
      "backend_name": "pop_art",
      "sample_img": "/imgstyles/popart.jpg"
    },
    {
      "style": "Photorealistic",
      "backend_name": "photorealistic",
      "sample_img": "/imgstyles/photorealistic.jpg"
    },
    {
      "style": "Anime",
      "backend_name": "anime",
      "sample_img": "/imgstyles/anime.jpg"
    },
    {
      "style": "Cartoon",
      "backend_name": "cartoon",
      "sample_img": "/imgstyles/cartoon.jpg"
    },
    {
      "style": "Painting",
      "backend_name": "painting",
      "sample_img": "/imgstyles/painting.jpg"
    },
    {
      "style": "Sketch",
      "backend_name": "sketch",
      "sample_img": "/imgstyles/sketch.jpg"
    },
    {
      "style": "Watercolor",
      "backend_name": "watercolor",
      "sample_img": "/imgstyles/watercolor.jpg"
    },
    {
      "style": "Vintage",
      "backend_name": "vintage",
      "sample_img": "/imgstyles/vintage.jpg"
    },
    {
      "style": "Fantasy",
      "backend_name": "fantasy",
      "sample_img": "/imgstyles/fantasy.jpg"
    },
    {
      "style": "Cyberpunk",
      "backend_name": "cyberpunk",
      "sample_img": "/imgstyles/cyberpunk.jpg"
    },
    {
      "style": "Minimalist",
      "backend_name": "minimalist",
      "sample_img": "/imgstyles/minimalistic.jpg"
    },
    {
      "style": "Pixel Art",
      "backend_name": "pixel_art",
      "sample_img": "/imgstyles/pixel-art.jpg"
    },
    {
      "style": "Comics",
      "backend_name": "comic_book",
      "sample_img": "/imgstyles/comic.jpg"
    },
    {
      "style": "Steampunk",
      "backend_name": "steampunk",
      "sample_img": "/imgstyles/steampunk.jpg"
    },
    {
      "style": "Gothic",
      "backend_name": "gothic",
      "sample_img": "/imgstyles/gothic.jpg"
    },
    {
      "style": "Surreal",
      "backend_name": "surreal",
      "sample_img": "/imgstyles/surreal.jpg"
    },

    {
      "style": "Graffiti",
      "backend_name": "graffiti",
      "sample_img": "/imgstyles/graffiti.jpg"
    },
    {
      "style": "Baroque",
      "backend_name": "baroque",
      "sample_img": "/imgstyles/baroque.jpg"
    }
  ]

  let audioTypes = [
    {
      "screen_name": "Random",
      "name": "random",
      "file": "/imgstyles/shuffle.png"
    },
    {
      "screen_name": "Onyx",
      "name": "onyx",
      "file": "/audio/onyx.wav"
    },
    {
      "screen_name": "Alloy",
      "name": "alloy",
      "file": "/audio/alloy.wav"
    },
    {
      "screen_name": "Echo",
      "name": "echo",
      "file": "/audio/echo.wav"
    },
    {
      "screen_name": "Fable",
      "name": "fable",
      "file": "/audio/fable.wav"
    },
    {
      "screen_name": "Nova",
      "name": "nova",
      "file": "/audio/nova.wav"
    },
    {
      "screen_name": "John",
      "name": "shimmer",
      "file": "/audio/shimmer.wav"
    }
  ]
  const [abortController, setAbortController] = useState(null);

  const [open, setOpen] = useState(false)


  const options = {
    headers: [HEADER.WEEKLY]
  };
  // const [value, setValue] = useState({ value: "0 30 10 * * MON *", text: " At 10:30 AM, only on Monday" })
  const [value, setValue] = useState(value_)



  function DialogClose() {
    const ope = open;
    setOpen(!ope)
    // setTheme({})
    // setschedulerName('')
    // setValue({ value: "0 30 10 * * MON *", text: " At 10:30 AM, only on Monday" })
    // setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }


  function goToDashboard() {
    navigate("/dashboard");
  }


  async function logOut() {
    console.log("Logging out");
    signOut(auth).then(() => {
      console.log("Signed out");
      navigate('/home')
    }).catch((error) => {
      console.log("Signed out failed");
    })

  }


  function getMapById(id) {
    return imgStyles.find(item => item.backend_name === id);
}

function getVoiceById(id) {
  console.log(cronstatus);
  return audioTypes.find(item => item.name === id);
}

  return (
    <div>

              
              <div className="ml-auto flex items-center ">

                <Dialog open={open} onOpenChange={DialogClose} style={{ zIndex: 20 }}>
                  <DialogContent className="sm:max-w-[425px] overflow-y-scroll max-h-screen w-11/12 gap-1 mt-3 mb-3">
                    <DialogHeader className=" text-xl">
                      Scheduler Details
                    </DialogHeader>
                    <Separator className=" mb-3"/>
                    {/* <div className=" row-span-1"> */}
                    <Label className=" text-lg">Scheduler name: </Label>
                    <div className=" text-neutral-600 pb-4">{schedulerName} </div>
        
                    {/* </div> */}
                    <Label className="text-lg">Schedule config:</Label>
                    <div className=" text-neutral-600 pb-4">{value.text} </div>
        

                    <Label className=" text-lg">Theme: </Label>
                    <div className=" text-neutral-600 pb-4">{theme.name} </div>
        

                    <Label className="text-lg">Image Style: </Label>
                    <div className="flex items-center text-neutral-600 pb-4">
                                      <div className=" pr-4">
                                        <Avatar >
                                          <AvatarImage src={getMapById(imgstyle).sample_img} />
                                          <AvatarFallback>CN</AvatarFallback>
                                        </Avatar>
                                      </div>
                                      {getMapById(imgstyle).style}
                                    </div>
        

                    <Label className=" text-lg">Voice model: </Label>
                    <div className=" text-neutral-600 pb-4">{getVoiceById(audiotype).screen_name} </div>          
                   
                    <Label className=" text-lg">Youtube account to post video: </Label>
                    <div className=" text-neutral-600 pb-4">{ytemailid} </div>


                    <Label className=" text-lg">Status: </Label>
                    <div className=" text-neutral-600 pb-4">
                      
                    {cronstatus == 'Active' ? <Badge className=" text-base">{cronstatus}</Badge> :
                  cronstatus == 'Paused - Buy credits to resume' ? <Badge className=" pl-0 rounded-none text-red-700 border-0 text-base" variant="outline">{cronstatus}</Badge> : <Badge className=" text-base" variant="secondary">{cronstatus}</Badge>
                }

                    </div>
                    
                  </DialogContent>

                </Dialog>
             
              </div>

              <div role="menuItem" className="relative  flex  select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50" onClick={() => setOpen(true)}>View Details</div>
                              
            </div>
  )
}
export default ViewSchedulerConfig;