// import Image from "next/image"
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useState, useEffect, use } from 'react'
import { useToast } from "@/components/ui/use-toast"
import { Separator } from "@/components/ui/separator"
import { AudioPlayer } from 'react-audio-play';
import Cron from 'react-cron-generator'
import './cron-builder.css'
import { Toaster } from "@/components/ui/toaster"
import { PulseLoader } from "react-spinners";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
// import { RocketIcon } from "@radix-ui/react-icons"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import Confetti from 'react-confetti'
import SchedulerList from "./SchedulerList";
import { HEADER } from 'react-cron-generator';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"

import {
  ChevronLeft,
  Home,
  LineChart,
  RefreshCcwDot,
  Clapperboard,
  BadgeInfo,
  Package,
  Package2,
  PanelLeft,
  PlusCircle,
  Search,
  Settings,
  RocketIcon,
  ShoppingCart,
  Upload,
  Users2,
} from "lucide-react"
import TimezoneSelect from "react-timezone-select"

import { Skeleton } from "@/components/ui/skeleton"
import { Badge } from "@/components/ui/badge"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import parser from 'cron-parser';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectGroup
} from "@/components/ui/select"
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Textarea } from "@/components/ui/textarea"
import moment from 'moment-timezone';

import {
  ToggleGroup,
  ToggleGroupItem,
} from "@/components/ui/toggle-group"
import { Toggle } from "@/components/ui/toggle"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"

import DashboardLayout from "@/custom_component/dashboardLayout"
import { auth } from "../firebase-config"
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";
import { allTimezones } from "react-timezone-select";
import SchedulerConfig from "./SchedulerConfig";
const Scheduler = () => {
  const [schedulers, setSchedulers] = useState([]);
  const [idToken, setIdToken] = useState('');
  const [themes, setThemes] = useState([]);
  const [imgstyle, setImgstyle] = useState('');
  const [audiotype, setAudiotype] = useState('');
  const [theme, setTheme] = useState({});
  const [imageSize, setImageSize] = useState(" size-16");
  const [ytemailid, setytemailid] = useState('')
  const [schedulerName, setschedulerName] = useState('');
  const { toast } = useToast()
  let navigate = useNavigate();
  const [ytemails, setytemails] = useState([])
  const [utctime, setUtcTime] = useState('')
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  )

  let imgStyles = [
    {
      "style": "Random",
      "backend_name": "random",
      "sample_img": "/imgstyles/shuffle.png"
    },
    {
      "style": "Pop Art",
      "backend_name": "pop_art",
      "sample_img": "/imgstyles/popart.jpg"
    },
    {
      "style": "Photorealistic",
      "backend_name": "photorealistic",
      "sample_img": "/imgstyles/photorealistic.jpg"
    },
    {
      "style": "Anime",
      "backend_name": "anime",
      "sample_img": "/imgstyles/anime.jpg"
    },
    {
      "style": "Cartoon",
      "backend_name": "cartoon",
      "sample_img": "/imgstyles/cartoon.jpg"
    },
    {
      "style": "Painting",
      "backend_name": "painting",
      "sample_img": "/imgstyles/painting.jpg"
    },
    {
      "style": "Sketch",
      "backend_name": "sketch",
      "sample_img": "/imgstyles/sketch.jpg"
    },
    {
      "style": "Watercolor",
      "backend_name": "watercolor",
      "sample_img": "/imgstyles/watercolor.jpg"
    },
    {
      "style": "Vintage",
      "backend_name": "vintage",
      "sample_img": "/imgstyles/vintage.jpg"
    },
    {
      "style": "Fantasy",
      "backend_name": "fantasy",
      "sample_img": "/imgstyles/fantasy.jpg"
    },
    {
      "style": "Cyberpunk",
      "backend_name": "cyberpunk",
      "sample_img": "/imgstyles/cyberpunk.jpg"
    },
    {
      "style": "Minimalist",
      "backend_name": "minimalist",
      "sample_img": "/imgstyles/minimalistic.jpg"
    },
    {
      "style": "Pixel Art",
      "backend_name": "pixel_art",
      "sample_img": "/imgstyles/pixel-art.jpg"
    },
    {
      "style": "Comics",
      "backend_name": "comic_book",
      "sample_img": "/imgstyles/comic.jpg"
    },
    {
      "style": "Steampunk",
      "backend_name": "steampunk",
      "sample_img": "/imgstyles/steampunk.jpg"
    },
    {
      "style": "Gothic",
      "backend_name": "gothic",
      "sample_img": "/imgstyles/gothic.jpg"
    },
    {
      "style": "Surreal",
      "backend_name": "surreal",
      "sample_img": "/imgstyles/surreal.jpg"
    },

    {
      "style": "Graffiti",
      "backend_name": "graffiti",
      "sample_img": "/imgstyles/graffiti.jpg"
    },
    {
      "style": "Baroque",
      "backend_name": "baroque",
      "sample_img": "/imgstyles/baroque.jpg"
    }
  ]

  let audioTypes = [
    {
      "screen_name": "Random",
      "name": "random",
      "file": "/imgstyles/shuffle.png"
    },
    {
      "screen_name": "Onyx",
      "name": "onyx",
      "file": "/audio/onyx.wav"
    },
    {
      "screen_name": "Alloy",
      "name": "alloy",
      "file": "/audio/alloy.wav"
    },
    {
      "screen_name": "Echo",
      "name": "echo",
      "file": "/audio/echo.wav"
    },
    {
      "screen_name": "Fable",
      "name": "fable",
      "file": "/audio/fable.wav"
    },
    {
      "screen_name": "Nova",
      "name": "nova",
      "file": "/audio/nova.wav"
    },
    {
      "screen_name": "John",
      "name": "shimmer",
      "file": "/audio/shimmer.wav"
    }
  ]
  const [abortController, setAbortController] = useState(null);

  const [loading, setLoading] = useState(false);
  const [customStyle, setCustomStyle] = useState(true)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        user.getIdToken().then(function (idToken) {  // <------ Check this line
          setIdToken(idToken);
        });
        // ...
      } else {
        navigate("/")
      }
    });
  }, [])

  useEffect(() => {
    if (!idToken) {
      return;
    }
    getThemes();

  }, [idToken]);

  useEffect(() => {
    if (!idToken) return;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchSchedules = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_schedule_list`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'app-token': idToken,
          },
          signal,
        });

        if (response.ok) {
          const { data: scheduleList, success: success } = await response.json();
          if (success == true) {
            setSchedulers(scheduleList);
          }
          else {
            toast({ title: "Something went wrong", description: "Please try again later" });
          }
        } else if (response.status === 404 || response.status === 401) {
          navigate("/");
          toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
        } else {
          const errorData = await response.json();
          console.error('Error:', errorData.message || response.statusText);
          toast({ title: "Something went wrong", description: "Please try again" });
        }
        setLoading(false);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error("Fetch error:", error);
        }
        setLoading(false);
      }
    };

    fetchSchedules();

    return () => {
      controller.abort();
    };
  }, [idToken]);

  const options = {
    headers: [HEADER.WEEKLY]
  };

  const [value, setValue] = useState({ value: "0 30 10 * * MON *", text: " At 10:30 AM, only on Monday" })

  const getThemes = async () => {
    try {

      const response = await fetch(`${import.meta.env.VITE_ENV}/content/get_themes`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken
        }
      });

      if (response.status === 200) {
        let themesData = await response.json();
        let themesDataList = themesData['data'];
        setThemes(themesDataList)

      } else if (response.status === 404 || response.status == 401) {
        logOut();
        toast({
          title: "Session Closed",
          description: "Facing issue with session. Please try again",
        })
        navigate("/");
      }
      else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({
          title: "Something went wrong",
          description: "Please try again",
        })
      }


    } catch {
      console.log("Gmail Login Failed");


    }

  }

  function updateCronExpression(cronExpression, utcTime) {
    // Split the cron expression into its components
    let cronParts = cronExpression.split(' ');

    // Extract hours and minutes from the UTC time
    let [utcHours, utcMinutes] = utcTime.split(':');

    // Update the cron parts with the extracted UTC hours and minutes
    cronParts[1] = utcMinutes;
    cronParts[2] = utcHours;

    // Join the updated cron parts back into a single string
    let updatedCronExpression = cronParts.join(' ');

    return updatedCronExpression;
  }

  function getUTCFromLocal(timedetail, timezone) {
    // Extract time from the timedetail string
    const timeMatch = timedetail.match(/\d{1,2}:\d{2}\s[APM]{2}/);

    if (!timeMatch) {
      return 'Invalid input format';
    }

    const time = timeMatch[0];

    // Construct the local time string in the format 'YYYY-MM-DD HH:mm A'
    const now = moment.tz(timezone);
    const localTime = now.format('YYYY-MM-DD') + ` ${time}`;

    // Parse the local time and convert to UTC
    const utcTime = moment.tz(localTime, 'YYYY-MM-DD hh:mm A', timezone).utc().format('HH:mm:ss [UTC]');

    return utcTime;
  }

  function DialogClose() {
    const ope = open;
    setOpen(!ope)
    setTheme({})
    setschedulerName('')
    setValue({ value: "0 30 10 * * MON *", text: " At 10:30 AM, only on Monday" })
    // setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }


  function convertCronToUTC(cronString, timezone) {
    try {
      // Parse the cron expression without considering time zone
      console.log(cronString);
      const interval = parser.parseExpression(cronString);
      console.log(cronString);
      // Get the next occurrence in the specified time zone
      const nextDateInTZ = moment.tz(interval.next().toISOString(), timezone);

      // Convert the next occurrence to UTC
      const nextDateInUTC = nextDateInTZ.clone().utc();

      // Construct the new cron expression in UTC
      const utcCronString = [
        nextDateInUTC.minute(),
        nextDateInUTC.hour(),
        nextDateInUTC.date(),
        nextDateInUTC.month() + 1, // months are 0-based in moment.js
        '*', // Keep the day of the week wildcard
        nextDateInUTC.day() // Day of the week in UTC
      ].join(' ');

      return utcCronString;
    } catch (err) {
      console.error('Error parsing cron string:', err);
      return null;
    }
  }


  function goToDashboard() {
    navigate("/dashboard");
  }

  const createSchedule = () => {
    const days = ["SUN", "FRI", "WED", "MON", "SAT", "THU", "TUE"];
    if ('name' in theme == false) {
      toast({ title: "Theme is missing", description: "Choose a theme" });
    }
    else if (schedulerName == '') {
      toast({ title: "Scheduler name is missing", description: "Give a name to scheduler" });
    }

    else if (!days.some(day => value.value.includes(day))) {
      toast({ title: "Schedule is missing", description: "Choose a schedule" });
    }
    else if (audiotype == '') {
      toast({ title: "Audio Type is missing", description: "Choose a voiceover audio" });
    }
    else if (imgstyle == '') {
      toast({ title: "Image Type is missing", description: "Choose a image style" });
    }
    else {

      createScheduleAPI();

    }
  }

  const createScheduleAPI = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_ENV}/content/create_schedule`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'app-token': idToken,
        },
        body: JSON.stringify({
          'name': schedulerName,
          'value': value,
          'theme': theme.id,
          'theme_name': theme.name,
          'utc_time': getUTCFromLocal(value.text, selectedTimezone),
          'utc_cron': updateCronExpression(value.value, getUTCFromLocal(value.text, selectedTimezone)),
          "timezone": selectedTimezone,
          "image_style": imgstyle,
          "audio_type": audiotype,
          'yt_email': ytemailid
        })
      });

      if (response.ok) {
        const { data: data, success: success, error_code: error_code, quota_exceeded: quota_exceeded } = await response.json();
        if (!success && error_code == 402) {
          toast({ title: "Mutliple Youtube accounts found. Choose one ", description: "" });
          setytemails(data)
          setytemailid(data[0])
        }
        else if (!success && error_code == 500) {
          toast({ title: "Connect Youtube account from Profile section", description: "Go to Profile and click Connect Youtube to Integrate." });
          DialogClose()

        }
        else if (!success && error_code == 405) {
          toast({ title: "You do not have enough credits to schedule video", description: "Consider upgrading your plan or buy credits" });
          DialogClose()

        }
        if (success == true) {
          toast({ title: "Schedule created", description: "Successfully created the schedule." });
          setSchedulers([data, ...schedulers])
          DialogClose()
          setOpen(false)
        }

      } else if (response.status === 404 || response.status === 401) {
        navigate("/");
        toast({ title: "Session Closed", description: "Facing issue with session. Please try again" });
        DialogClose()
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData.message || response.statusText);
        toast({ title: "Something went wrong", description: "Please try again" });
        DialogClose()
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Fetch error:", error);
        DialogClose()
      }
    }
  };


  async function logOut() {
    console.log("Logging out");
    signOut(auth).then(() => {
      console.log("Signed out");
      navigate('/home')
    }).catch((error) => {
      console.log("Signed out failed");
    })

  }

  return (
    <div className="flex min-h-screen w-full lg:pl-24 flex-col bg-muted/40">
      <DashboardLayout />
      <div className="flex flex-col gap-48 lg:gap-60 xl:gap-60  2xl:gap-60  sm:py-4 sm:pl-36 pl-1 lg:pl-12 2xl:pl-12">
        <main className="grid flex-1 items-start gap-4 p-8 sm:px-6 sm:py-0 md:gap-8">
          <Tabs defaultValue="all">
            <SchedulerConfig schedulers={schedulers} setSchedulers={setSchedulers} idToken={idToken}/>
            <TabsContent value="all">
              <Card x-chunk="dashboard-06-chunk-0">
                <CardHeader>
                  <CardTitle> Schedulers</CardTitle>
                  {/* <div className=" content-end">
                    <Pagination className=" hidden md:justify-end md:grid lg:justify-end lg:grid xl:justify-end xl:grid">
                      <PaginationContent>
                        <PaginationItem className=" cursor-pointer" >
                          <PaginationPrevious
                            onClick={onClickPrevious}
                            aria-disabled={disabledPrevious}
                            tabIndex={currentPage <= 1 ? -1 : undefined}
                            className={
                              currentPage <= 1 ? "pointer-events-none opacity-50" : undefined
                            }
                          />
                        </PaginationItem>
      
                        <PaginationItem className=" cursor-pointer">
                          <PaginationNext
                            onClick={onClickNext}
                            aria-disabled={disabledNext}
                            className={
                              totalCount <= currentPage * postsPerPage ? "pointer-events-none opacity-50" : undefined
                            }
                          />
                        </PaginationItem>
                      </PaginationContent>
                    </Pagination>
                  </div> */}
                </CardHeader>
                {!loading ? <SchedulerList schedulers={schedulers} setScheduler={setSchedulers} loading={loading} idToken={idToken} /> :
                  <div className="flex justify-center items-center p-4">Fetching your schedulers ...</div>}
              </Card>
            </TabsContent>
            {/* <div className=" content-end">
                    <Pagination className=" grid md:hidden lg:hidden xl:hidden">
                      <PaginationContent>
                        <PaginationItem className=" cursor-pointer" >
                          <PaginationPrevious
                            onClick={onClickPrevious}
                            aria-disabled={disabledPrevious}
                            tabIndex={currentPage <= 1 ? -1 : undefined}
                            className={
                              currentPage <= 1 ? "pointer-events-none opacity-50" : undefined
                            }
                          />
                        </PaginationItem>
                        <PaginationItem>
          <PaginationLink href="#">1</PaginationLink>
        </PaginationItem>
                        <PaginationItem className=" cursor-pointer">
                          <PaginationNext
                            onClick={onClickNext}
                            aria-disabled={disabledNext}
                            className={
                              totalCount <= currentPage * postsPerPage ? "pointer-events-none opacity-50" : undefined
                            }
                          />
                        </PaginationItem>
                      </PaginationContent>
                    </Pagination>
                  </div> */}
          </Tabs>
        </main>
        <div className="flex flex-root pl-8 pr-8 text-sm sm:gap-4 sm:py-4 sm:pl-8 ">* Upcoming scheduled videos appears under Dashboard's Upcoming tab. Update if needed.
        </div>
      </div>
      <Toaster />

    </div>
  )
}
export default Scheduler;