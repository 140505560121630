import { useState, useEffect } from 'react'
import { Button } from '@/components/ui/button';
import { Outlet, Link, useNavigate } from "react-router-dom";
import { auth } from "./../firebase-config"
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";
const Home = () => {
    const [count, setCount] = useState(0);
    const [loggedin, setLoggedin] = useState(false)

    let navigate = useNavigate();
    useEffect(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          user.getIdToken().then(function(idToken) {  // <------ Check this line
            setLoggedin(true);
         });
          // ...
        } else {
          setLoggedin(false);
        }
      });
    }, [])

    function goToDashboard(){
      navigate("/dashboard");
    }
    function goToLogin(){
      navigate("/login");
    }
    return (
      <>
      <div className=' flex justify-end'>
        {/* <Button>Home</Button> <br /><br /> */}
        {loggedin? <Button onClick={goToDashboard} className=" m-4">Dashboard</Button>
        : <Button onClick={goToLogin} className=" m-4"> Login </Button>}
       </div>
    <div class="   justify-center flex-row m-8">
    <h1 className="content-center text-center scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
    Turn Your Stories into Viral YouTube Shorts in Minutes!
    </h1>
    <h4 className="content-center text-center scroll-m-20 text-lg font-extrabold tracking-tight lg:text-lg text-gray-600">
    Transform your text content into engaging, high-impact YouTube Shorts effortlessly.
    </h4>
</div>
      </>
    )
   }

export default Home;
