// import Image from "next/image"
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useState, useEffect } from 'react'

import {
    File,
    Home,
    LineChart,
    AreaChart,
    ListFilter,
    MoreHorizontal,
    User,
    Package,
    Package2,
    PanelLeft,
    PlusCircle,
    LogOut,
    Search,
    Settings,
    CalendarCheck,
    ShoppingCart,
    Users2,
} from "lucide-react"

import { Badge } from "@/components/ui/badge"
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"

import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"

import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarSeparator,
    MenubarShortcut,
    MenubarTrigger,
} from "@/components/ui/menubar"
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Input } from "@/components/ui/input"
import { Sheet, SheetContent, SheetTrigger, SheetClose } from "@/components/ui/sheet"
import { useToast } from "@/components/ui/use-toast"

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"
import { auth } from "./../firebase-config"
import { onAuthStateChanged, signOut, getIdToken } from "firebase/auth";

function DashboardLayout(props) {
    const [content, setContent] = useState('');
    let activeTab = props.activeTab;
    const [idToken, setIdToken] = useState('');
    const [contentRequestID, setContentRequestID] = useState({});
    const { toast } = useToast()
    let navigate = useNavigate();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                user.getIdToken().then(function (idToken) {  // <------ Check this line
                    setIdToken(idToken);
                });
                // ...
            } else {
                navigate("/")
            }
        });
    }, [])

   
  async function logOut() {
    console.log("Logging out");
    signOut(auth).then(() => {
      console.log("Signed out");
      sessionStorage.removeItem('SG-Auth Token');
    navigate('/home')
    }).catch((error) => {
      console.log("Signed out failed");
    })
    
  }
    return (
        <div >
            <div >
                <aside className="fixed inset-y-0 left-0 z-10 hidden w-17 flex-col border-r bg-background sm:flex">
                    <nav className="flex flex-col gap-4 px-2 sm:py-5">


                        <Link
                            href="#"
                            className="group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:h-8 md:w-8 md:text-base"
                        >
                            <Package2 className="h-4 w-4 transition-all group-hover:scale-110" />
                            <span className="sr-only">AIshorts</span>

                        </Link>


                        <Menubar>
                            <MenubarMenu className="flex h-9 w-9">
                                <MenubarTrigger>
                                    <Link
                                        to="/dashboard"
                                        className="pr-3 "
                                    >
                                        <Home className="h-5 w-5" />
                                    </Link>
                                    <NavigationMenu>
                                        <NavigationMenuList>
                                            <NavigationMenuItem>
                                                <NavigationMenuLink>
                                                    <Link to="/dashboard">
                                                        <NavigationMenuLink >
                                                            Dashboard
                                                        </NavigationMenuLink>
                                                    </Link></NavigationMenuLink>
                                            </NavigationMenuItem>
                                        </NavigationMenuList>
                                    </NavigationMenu>
                                </MenubarTrigger>

                            </MenubarMenu>
                        </Menubar>

                        <Menubar>
                            <MenubarMenu className="flex h-9 w-9 text-muted-foreground transition-colors hover:text-foreground">
                                <MenubarTrigger >
                                    <Link
                                        to="/scheduler"
                                        className="pr-3"
                                    >
                                        <CalendarCheck className="h-5 w-5" />
                                        <span className="sr-only">Schedulers</span>
                                    </Link>
                                    <NavigationMenu>
                                        <NavigationMenuList>
                                            <NavigationMenuItem>
                                                <NavigationMenuLink>
                                                    <Link to="/scheduler">
                                                        <NavigationMenuLink >
                                                            Schedulers
                                                        </NavigationMenuLink>
                                                    </Link></NavigationMenuLink>
                                            </NavigationMenuItem>
                                        </NavigationMenuList>
                                    </NavigationMenu>
                                </MenubarTrigger>

                            </MenubarMenu>
                        </Menubar>
                        {/* <Menubar>
                            <MenubarMenu className="flex h-9 w-9 text-muted-foreground transition-colors hover:text-foreground">
                                <MenubarTrigger >
                                    <Link
                                        href="#"
                                        className="pr-3"
                                    >
                                        <LineChart className="h-5 w-5" />
                                        <span className="sr-only">Analytics</span>
                                    </Link>
                                    <NavigationMenu>
                                        <NavigationMenuList>
                                            <NavigationMenuItem>
                                                <NavigationMenuLink>
                                                    <Link to="/">
                                                        <NavigationMenuLink >
                                                            Analytics
                                                        </NavigationMenuLink>
                                                    </Link></NavigationMenuLink>
                                            </NavigationMenuItem>
                                        </NavigationMenuList>
                                    </NavigationMenu>
                                </MenubarTrigger>

                            </MenubarMenu>
                        </Menubar> */}

                        <Menubar>
                            <MenubarMenu className="flex h-9 w-9 text-muted-foreground transition-colors hover:text-foreground">
                                <MenubarTrigger >
                                    <Link
                                        href="/profile"
                                        className="pr-3"
                                    >
                                        <User className="h-5 w-5" />
                                        <span className="sr-only">Profile</span>
                                    </Link>
                                    <NavigationMenu>
                                        <NavigationMenuList>
                                            <NavigationMenuItem>
                                                <NavigationMenuLink>
                                                    <Link to="/profile">
                                                        <NavigationMenuLink >
                                                            Profile
                                                        </NavigationMenuLink>
                                                    </Link></NavigationMenuLink>
                                            </NavigationMenuItem>
                                        </NavigationMenuList>
                                    </NavigationMenu>
                                </MenubarTrigger>

                            </MenubarMenu>
                        </Menubar>

                        {/* <TooltipProvider>

          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                href="#"
                className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
              >
                <Home className="h-5 w-5" />
                <span className="sr-only">Dashboard</span>
              </Link>
            </TooltipTrigger>
            <TooltipContent side="right">Dashboard</TooltipContent>
          </Tooltip>
          </TooltipProvider>
          <TooltipProvider>

          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                href="#"
                className="flex h-9 w-9 items-center justify-center rounded-lg bg-accent text-accent-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
              >
                <ShoppingCart className="h-5 w-5" />
                <span className="sr-only">Orders</span>
              </Link>
            </TooltipTrigger>
            <TooltipContent side="right">Orders</TooltipContent>
          </Tooltip>
          </TooltipProvider>
          <TooltipProvider>

          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                href="#"
                className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
              >
                <Package className="h-5 w-5" />
                <span className="sr-only">Products</span>
              </Link>
            </TooltipTrigger>
            <TooltipContent side="right">Products</TooltipContent>
          </Tooltip>
          </TooltipProvider>
          <TooltipProvider>

          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                href="#"
                className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
              >
                <Users2 className="h-5 w-5" />
                <span className="sr-only">Customers</span>
              </Link>
            </TooltipTrigger>
            <TooltipContent side="right">Customers</TooltipContent>
          </Tooltip>
          </TooltipProvider>
          <TooltipProvider>
 
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                href="#"*
                className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
              >
                <LineChart className="h-5 w-5" />
                <span className="sr-only">Analytics</span>
              </Link>
            </TooltipTrigger>
            <TooltipContent side="right">Analytics</TooltipContent>
          </Tooltip>
          </TooltipProvider> */}
                    </nav>
                    <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
                        <TooltipProvider>

                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Link
                                        href="#"
                                        className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
                                    >
                                        <LogOut className="h-5 w-5" onClick={logOut}/>
                                        <span className="sr-only">Settings</span>
                                    </Link>
                                </TooltipTrigger>
                                <TooltipContent side="right">Logout</TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </nav>
                </aside>
                <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
                    <Sheet>
                        <SheetTrigger asChild>
                            <Button size="icon" variant="outline" className="sm:hidden">
                                <PanelLeft className="h-5 w-5" />
                                <span className="sr-only">Toggle Menu</span>
                            </Button>
                        </SheetTrigger>
                        <SheetContent side="left" className="sm:max-w-xs">
                       
                            <nav className="grid gap-6 text-lg font-medium">
               
                                <Link
                                    to="#"
                                    className="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base"
                                >
                                    <Package2 className="h-5 w-5 transition-all group-hover:scale-110" />
                                    <span className="sr-only">AIshorts</span>
                                </Link>

                                <Link
                                    to="/dashboard"
                                    className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
                                >
                                    <Home className="h-5 w-5" />
                                    Dashboard
                                </Link>

                                <Link
                                    to="/scheduler"
                                    className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
                                >
                                    <CalendarCheck className="h-5 w-5" />
                                    Schedulers
                                </Link>
                                {/* <Link
                                    to="#"
                                    className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
                                >
                                    <LineChart className="h-5 w-5" />
                                    Analytics
                                </Link> */}
                                <Link
                                    to="/profile"
                                    className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
                                >
                                    <Users2 className="h-5 w-5" />
                                    Profile
                                </Link>
                               
                            </nav>
                            <nav className="mb-8 absolute inset-x-20 bottom-0 flex-col items-center gap-4 px-2 sm:py-5">
                        <TooltipProvider>

                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Link
                                        href="#"
                                        className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
                                    >
                                        <LogOut className="h-5 w-5" onClick={logOut}/>
                                        <span className="sr-only">Settings</span>
                                    </Link>
                                </TooltipTrigger>
                                <TooltipContent side="right">Logout</TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </nav>
                        </SheetContent>
                    </Sheet>
                    {/* <Breadcrumb className="hidden md:flex">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link href="#">Dashboard</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link href="#">Products</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>All Products</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb> */}
                    {/* <div className="relative ml-auto flex-1 md:grow-0">
                        <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                        <Input
                            type="search"
                            placeholder="Search..."
                            className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                        />
                    </div> */}
                    {/* <DropdownMenu >
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant="outline"
                                size="icon"
                                className="overflow-hidden rounded-full"
                            >
                                <img
                                    src="/placeholder-user.jpg"
                                    width={36}
                                    height={36}
                                    alt="Avatar"
                                    className="overflow-hidden rounded-full"
                                />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>My Account</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem>Settings</DropdownMenuItem>
                            <DropdownMenuItem>Support</DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem>Logout</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu> */}
                </header>
            </div>
        </div>
    )
}
export default DashboardLayout;